<template>
  <div class="form-group">
    <div class="clearfix">
      <select
        :id="id"
        :name="id"
        v-model="model"
        class="form-control"
        :class="[classComponent]"
        @input="input($event.target.value)"
        :disabled="localDisabled || localOptions.length == 0 ? 'disabled' : false"
      >

        <option v-if="unhideFirstOpt" :value="null" :disabled="disabledFirstOpt">
          {{ placeholder || "Select options" }}
        </option>
        <option v-else-if="!localOptions.find((el) => el.id == 0)" value="0" :disabled="disabledFirstOpt">
          {{ placeholder || "Select options" }}
        </option>
        <option v-for="(option, i) in localOptions" :key="i" :value="option.id">
          {{ option.name }}
        </option>
      </select>
      <div
        class="invalid-feedback text-right"
        v-if="invalidMessage && invalidMessage.length > 0"
      >
        <div
          v-for="(message, index) in invalidMessage"
          :key="message + '-' + index"
          :class="{
            'mb-1':
              invalidMessage.length > 1 && index + 1 != invalidMessage.length,
          }"
        >
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputSelect",
  props: [
    "id",
    "classComponent",
    "invalidMessage",
    "options",
    "placeholder",
    "value",
    "disabled",
    "unhideFirstOpt",
    "disabledFirstOpt",
  ],
  data() {
    return {
      model: this.value,
    };
  },
  computed: {
    localDisabled() {
      return this.disabled
    },
    localOptions() {
      return this.options
    }
  },
  methods: {
    input(val) {
      this.model = val;
      this.$emit("input", this.model);
    },
  },
  watch: {
    value(newVal) {
      this.model = newVal;
    },
  },
};
</script>
